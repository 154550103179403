import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { ViewportBreakpoints } from '../cssConstants';

const Container = styled.div`
  padding: 60px 0;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 100px 0;
  }
`;

const ContentWrapper = ({ children }) => (
  <Container>
    {children}
  </Container>
);

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentWrapper;
